import { PlatformUserModel } from "@/store/modules/User/model";
import { ClientContentModel } from "@/store/modules/Client/model";
import { EventContentModel } from "@cruciallearning/puddle/models/event";
import api from "@/services/api";
import config from "@/config";
import { http } from "@/services/http";
import { AuthUser } from "@cruciallearning/puddle/auth";
import { AuditronResponse, DistinctDataFields, TransactionOptions } from "@cruciallearning/puddle/models/auditron";

const Auditron = {
  log(
    target: AuthUser | PlatformUserModel | ClientContentModel | EventContentModel,
    action: string,
    scope: string,
    options?: LogOptions
  ): void {
    action = action.toUpperCase();
    let collection = "";
    let salesforceId: string | undefined;
    const tags = options?.tags ? options.tags : [];

    if (this.isPlatformUserModel(target)) {
      const obj = target as PlatformUserModel;
      collection = "platformUser";
      salesforceId = obj.clientAssociations?.at(0)?.clientId;
      this.addTag(tags, `${obj.firstName}.${obj.lastName}`);
    } else if (this.isAuthUser(target)) {
      const obj = target as AuthUser;
      collection = "platformUser";
      this.addTag(tags, `${obj.given_name}.${obj.family_name}`);
    } else if (this.isClientContentModel(target)) {
      const obj = target as ClientContentModel;
      collection = "client";
      salesforceId = obj.externalId;
    } else if (this.isEventContentModel(target)) {
      const obj = target as EventContentModel;
      collection = "learningEvent";
      salesforceId = obj.salesForceId;
      this.addTag(tags, obj.referenceNumber);
    } else return;

    this.addTag(tags, action);
    this.addTag(tags, scope);

    const message = {
      action: action,
      targetObjectId: target.id,
      targetCollection: collection,
      salesforceId: salesforceId,
      tags: tags,
      changes: [
        {
          _id: options?.changeId,
          scope: scope,
          oldValue: options?.oldValue,
          newValue: options?.newValue,
        },
      ],
    };
    console.log("log: " + JSON.stringify(message));
    api.auditron.add(config.backendUrl, message, http);
  },

  addTag(tags?: string[], value?: string): void {
    if (!tags) tags = [];
    if (!value) return;

    const values = value.split(".");
    for (const newValue of values) {
      const value = newValue.trim().toLowerCase();
      if (value && value !== "undefined" && value !== "null") {
        if (!tags.includes(value)) tags.push(value);
      }
    }
  },

  isAuthUser(target: unknown): boolean {
    return (target as AuthUser).token != undefined;
  },
  isPlatformUserModel(target: unknown): boolean {
    return (target as PlatformUserModel).platformUserType != undefined;
  },
  isClientContentModel(target: unknown): boolean {
    return (target as ClientContentModel).courseTokens != undefined;
  },
  isEventContentModel(target: unknown): boolean {
    return (target as EventContentModel).originalSeatCount != undefined;
  },

  async getPage(size: number, page: number, objectClass: string, objectId: string): Promise<AuditronResponse> {
    return await api.auditron.getPage(config.backendUrl, size, page, objectClass, objectId, http);
  },
  async getTransactions(options: TransactionOptions): Promise<AuditronResponse> {
    return await api.auditron.getTransactions(config.backendUrl, options, http);
  },
  async downloadTransactions(filename: string, options: TransactionOptions): Promise<void> {
    return await api.auditron.downloadTransactions(config.backendUrl, filename, options, http);
  },
  async downloadAttendanceRecord(event: EventContentModel): Promise<void> {
    return await api.auditron.downloadAttendanceRecord(config.backendUrl, event, http);
  },
  async getDistinctData(salesforceId?: string): Promise<DistinctDataFields> {
    return await api.auditron.getDistinctData(config.backendUrl, http, salesforceId);
  },
};
export default Auditron;

export interface LogOptions {
  tags?: string[];
  oldValue?: string;
  newValue?: string;
  changeId?: string;
}
